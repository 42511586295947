import { createContext, useContext, useReducer } from 'react';
import { useHistory } from 'react-router';

const DrawerContext: any = createContext({});
const DrawerConsumer = DrawerContext.Consumer;
const initialState = {
  visible: true,
  confirmToClose: null,
};

const reducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case 'open':
      return { ...state, visible: true };
    case 'close':
      return { ...state, visible: false, callbackURL: action.payload };
    case 'confirmToClose':
      return { ...state, confirmToClose: action.payload };
    default:
      return initialState;
  }
};

function DrawerProvider({ children }: any) {
  let [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DrawerContext.Provider value={{ state, dispatch }}>
      {children}
    </DrawerContext.Provider>
  );
}

type DrawerContextType = {
  state: any;
  dispatch: Function;
};

export const useDrawer = () => {
  const history = useHistory();
  const { state: drawer, dispatch } =
    useContext<DrawerContextType>(DrawerContext);
  const open = () => dispatch({ type: 'open' });
  const close = (callbackURL: string | false = false) => {
    if (callbackURL && typeof callbackURL === 'string') {
      history.push(callbackURL);
    }
    dispatch({ type: 'close', visible: false, payload: callbackURL });
  };
  const setConfirmToClose = (payload: any) =>
    dispatch && dispatch({ type: 'confirmToClose', payload });
  return { drawer, open, close, setConfirmToClose };
};

export { DrawerContext, DrawerProvider, DrawerConsumer };
