import Layout from './Layout';
import {
  // getProfileRequest,
  signInStart,
  signOut,
  //handleUnauthorizedAccess,
} from '@/shared/state/profile/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { MENU_DEFAULT_ITEMS } from '@/shared/constants/navigation';

export const mapStateToProps = (
  { profile, properties: { loader = {}, theme } },
  {
    route: { props: { showMenu = true } = {}, rules: { isPrivate } = {} } = {},
  },
) => ({
  profile,
  theme,
  showHeader: isPrivate && profile.logged,
  showMenu: isPrivate && profile.logged && showMenu,
  showFooter: !profile.logged,
  showLoader: loader.show,
  menuItems: MENU_DEFAULT_ITEMS,
  loader,
});

export const mapDispatchToProps = (dispatch) => ({
  signInStart: (data) => {
    dispatch(signInStart(data));
  },
  signOut: (data) => {
    dispatch(signOut(data));
  },
  handleRouteAccess: ({ rules, isLogged }) => {
    if (rules.isPrivate && !isLogged) {
      // dispatch(getProfileRequest());
      // dispatch(handleUnauthorizedAccess());
    }
  },
});

const ConnectedLayout = connect(mapStateToProps, mapDispatchToProps)(Layout);

export default withRouter(ConnectedLayout);
