import { apiFail, apiSuccess } from '../../state/api/actions';
import { API_BASE_URL } from '@/shared/constants/api';

const apiMiddleware =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    next(action);

    if (
      !action.type ||
      action.type === 'API_REQUEST' ||
      !action.type.endsWith('_REQUEST') ||
      !action.payload ||
      !action.payload.API_CONFIG ||
      !action.payload.API_CONFIG.path
    ) {
      return;
    }

    const {
      baseURL = API_BASE_URL,
      path,
      onSuccess = apiSuccess,
      onStart,
      onFail = apiFail,
    } = action.payload.API_CONFIG;

    if (onStart) {
      dispatch(onStart(path));
    }

    if (Array.isArray(path)) {
      Promise.all([
        ...path.map((currentPath) =>
          fetch(baseURL + currentPath, {
            credentials: 'include',
            mode: 'no-cors',
          }).then((res) => res.json()),
        ),
      ])
        .then((responses) => {
          dispatch(onSuccess(...responses));
        })
        .catch((errors) => dispatch(onFail(...[errors])));
    } else {
      fetch(baseURL + path, {
        credentials: 'include',
        mode: 'no-cors',
      })
        .then((res: Response) => res.json())
        .then((res) => {
          dispatch(onSuccess(res));
        })
        .catch((response) => {
          dispatch(onFail(response.data || response));
        });
    }
  };

export default apiMiddleware;
