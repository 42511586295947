import * as apiUtils from './api-utils';
import * as localizationUtils from './localization-utils';
import * as testsUtils from './tests-utils';
import moment, { duration } from 'moment';
import isPlainObject from 'lodash.isplainobject';
import { DATE_FORMAT_COMPLETE } from '../constants/ui';

/**
 * @method formatDate
 * @param {Date} date
 * @description Basic date formatting.
 * @returns {string}
 */
const formatDate = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

/**
 * @method getValueFromPath
 * @param obj
 * @param {string} path
 * @description Returns an <Object> property value from a string path.
 * @returns {any | string}
 */
const getValueFromPath = (obj: any, path: string) =>
  obj &&
  path
    ?.split('.')
    .reduce((result, prop) => (result == null ? undefined : result[prop]), obj);

/**
 * @method stringify
 * @param obj
 * @param excludeEmpty
 * @description Parse an object and converts to query-string.
 * @returns {string}
 */
const stringify = (obj: any, excludeEmpty?: boolean) =>
  Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .filter((item: string) => (excludeEmpty ? !item.endsWith('=') : item))
    .join('&');

/**
 * @method proxyToBooleanValues
 * @param {Object} input
 * @param removeDirty
 * @description Create a Object proxy to change the non boolean values
 * @return {Proxy}
 */
const proxyToBooleanValues = (input: Object, removeDirty?: boolean) => {
  if (!input) {
    return {};
  }
  const validator = function (
    target: any,
    prop: string | number | symbol,
    receiver: any,
  ) {
    if (typeof target[prop] === 'string' || typeof target[prop] === 'number') {
      if (removeDirty) {
        target[prop] = false;
        delete target[prop];
      } else {
        target[prop] = false;
      }
    } else if (typeof target[prop] === 'object') {
      target[prop] = new Proxy(target[prop], { get: validator });
    }
    return Reflect.get(target, prop, receiver);
  };

  return new Proxy(input, {
    get: validator,
  });
};

/**
 * @method getFieldValidationFromPath
 * @param obj
 * @param {string} path
 * @description Returns an validation schema <Object> property value from a string path.
 * @returns {any | string}
 */
const getFieldValidationFromPath = (obj: any, path: string = '') =>
  obj &&
  path
    .replace(/\./g, '.fields.')
    ?.split('.')
    .reduce((result, prop) => (result == null ? undefined : result[prop]), obj);

/**
 * @function appendSuffix
 * @description Add a text suffix and a increment if ends with a number
 *
 * @param {string} text
 * @param {string} suffix
 * @param {RegExp} suffixPattern
 * @return {string}
 */
const appendSuffix = (
  text: string,
  suffix: string = 'copy',
  suffixPattern: RegExp = /\s?copy\s?\d?\s?$/i,
) => {
  const hasSuffix = text.match(suffixPattern);

  let increment: number | string = hasSuffix
    ? parseInt(hasSuffix[0].replace(new RegExp(`\\s?${suffix}\\s?`, 'i'), '')) +
      1
    : 1;

  return `${text.replace(suffixPattern, '')} ${suffix} ${
    (!isNaN(increment) && increment) || 1
  }`.trim();
};

const formatDateTimeStr = (
  dateTime: string | undefined,
): string | undefined => {
  if (dateTime && typeof dateTime === 'string' && dateTime.length > 18) {
    return dateTime.slice(0, 19).replace('T', ' ');
  } else {
    return dateTime;
  }
};

type DateFormatParams = {
  format?: string;
  defaultVal?: string;
};

export const friendlyDateTime = (
  dateTime: string | undefined,
  { format = DATE_FORMAT_COMPLETE, defaultVal = '-' }: DateFormatParams = {},
): string | undefined =>
  moment(dateTime).isValid() ? moment(dateTime).format(format) : defaultVal;

export const friendlyDuration = (timeString?: string) => {
  if (!timeString) return '-';

  const timeComponents = timeString.split(':');
  const secondsSection = timeComponents.pop();
  const minutesSection = timeComponents.pop();
  const hoursSection = timeComponents.pop();

  const seconds = Math.round(parseFloat(secondsSection ?? '0'));
  const minutes = parseInt(minutesSection ?? '0');
  const hours = parseInt(hoursSection ?? '0');

  const secondsString = seconds ? `${seconds}s` : '';
  const minutesString = minutes ? `${minutes}m` : '';
  const hoursString = hours ? `${hours}h` : '';

  const formattedString = `${hoursString} ${minutesString} ${secondsString}`;

  return formattedString;
};

export {
  apiUtils,
  localizationUtils,
  testsUtils,
  proxyToBooleanValues,
  formatDate,
  formatDateTimeStr,
  getValueFromPath,
  getFieldValidationFromPath,
  stringify,
  appendSuffix,
};

// Removes any keys with undefined values
export const removeEmptyKeys = (obj: any): any => {
  const result: any = {};
  for (const key in obj) {
    const value = obj[key];
    if (value !== undefined) {
      if (isPlainObject(value)) {
        result[key] = removeEmptyKeys(value);
      } else {
        result[key] = value;
      }
    }
  }
  return result;
};

export const getError = async (err: any) => {
  const errCodeSuffix = err?.status ? ` (${err.status})` : '';
  const msgPrefix = `An error occurred${errCodeSuffix}: `;
  let message = 'If this error persists, please contact the development team.';
  if (typeof err?.json === 'function') {
    let res = null;
    try {
      res = await err.json();
      message = res?.message || '';
    } catch (e) {
      res = '';
    }
  } else {
    message = err?.message || '';
  }
  return msgPrefix + message;
};

/**
 * Return array of duplicate indexes based on the specified key
 * @param arr: Array of objects to be checked
 * @param k: Key to use to get the duplicate value
 */
export function getDuplicateIndexesByKey(arr: any[], k: string): any[] {
  const values = arr.map?.((item) => item?.[k]) ?? [];
  const duplicateIndexes = [];
  for (const [idx, v] of values.entries()) {
    if (v !== undefined) {
      const matchIdx = values.lastIndexOf(v);
      if (matchIdx !== idx) {
        duplicateIndexes.push(...[idx, matchIdx]);
      }
    }
  }
  return Array.from(new Set(duplicateIndexes));
}

export function getRandomString() {
  return Math.random().toString(36).slice(2, 7);
}

export const createFullPath = (
  path: string,
  params: Record<string, string>,
) => {
  Object.entries(params).forEach(([key, value]) => {
    path = path.replace(`:${key}`, value);
  });
  return path;
};

export const isMacOs = (): boolean => {
  return /Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.userAgent);
};

function getScore(c: string): number {
  const narrowChars = 'iljI.,;:';
  const thinChars = 'ftr ';
  const mediumChars = 'abcdeghknopqsuvxyz';
  const wideChars = 'mwABCDEFGHJKLNOPQRSTUVXYZ';
  const veryWideChars = 'MW';
  if (narrowChars.includes(c)) {
    return 0.5;
  } else if (thinChars.includes(c)) {
    return 0.75;
  } else if (mediumChars.includes(c)) {
    return 1;
  } else if (wideChars.includes(c)) {
    return 1.5;
  } else if (veryWideChars.includes(c)) {
    return 2;
  } else {
    return 1;
  }
}

export function estimateTextWidth(text: string): number {
  if (typeof text !== 'string') {
    return 0;
  }
  let widthScore = 0;
  for (const c of text) {
    widthScore += getScore(c);
  }
  return widthScore;
}

export function getDuration(startDate: string, endDate: string) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const durationObj = duration(endMoment.diff(startMoment));

  const durationFormatSlices: string[] = [];
  const addFormatSlice = (time: number, formatString: string) =>
    !!time && durationFormatSlices.push(`${time}${formatString}`);

  addFormatSlice(durationObj.days(), 'd');
  addFormatSlice(durationObj.hours(), 'h');
  addFormatSlice(durationObj.minutes(), 'm');
  addFormatSlice(durationObj.seconds(), 's');

  if (!durationFormatSlices.length) {
    addFormatSlice(durationObj.milliseconds(), 'ms');
  }

  return durationFormatSlices.join(' ');
}

export function getIfExists(
  obj: Record<string, any>,
  key?: string,
  fallback: string = '',
): any {
  return key && key in obj ? obj[key] : fallback;
}

export function downloadToCsv(arr: any[][], filename: string = 'data.csv') {
  const csvContent = arr.map((row) => row.join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
