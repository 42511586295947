import { Menu as NebulaMenu, View } from '@baosystems/nebula';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useRoles } from '@/shared/hooks/profile';
import { MenuItem } from '@/shared/interfaces/menu';

const { SubMenu } = NebulaMenu;
const MenuContainer = styled(View)(styles.component);

const Menu = ({
  isMobile,
  items,
}: any): React.FunctionComponentElement<any> => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { validateRolesAsync, getRoles } = useRoles();
  const [menuItems, setMenuItems] = useState<any>(null);

  const toQuery = (obj: any) =>
    obj
      ? '?' +
        Object.keys(obj)
          .map((key) => key + '=' + obj[key])
          .join('&')
      : '';

  const getSelectedKey = useCallback(
    (items: MenuItem[]) => {
      return items.filter((item: any) =>
        // By removing the last character, we can match both items and group endpoints
        // For example dataQualityChecks and dataQualityCheckGroups both match
        // the text 'dataQualityCheck'
        Boolean(
          pathname.includes(item?.to?.slice(0, -1) ?? '(neverMatchThis)'),
        ),
      )[0]?.key;
    },
    [pathname],
  );

  const getRolesValidations = useCallback(async () => {
    const userRoles = await getRoles();
    const getList = items?.map(
      async ({ hide, validate, ...item }: MenuItem) => {
        let shouldHide;

        if (typeof hide === 'function') {
          shouldHide = await Promise.resolve(hide(userRoles));
        }

        return {
          ...item,
          hide:
            shouldHide ||
            (await validate?.()) ||
            (item.roles && !(await validateRolesAsync(item.roles))),
        };
      },
    );

    Promise.all(getList).then((result) => !menuItems && setMenuItems(result));
  }, [validateRolesAsync, menuItems, items, getRoles]);

  useEffect(() => {
    if (!menuItems) {
      getRolesValidations();
    }
  }, [menuItems, getRolesValidations]);

  const renderMenuItems = useCallback((items: any) => {
    return items?.map(
      ({
        key,
        label: getLabel,
        to,
        query,
        icon: Icon,
        items: submenus,
        hide,
      }: any) => {
        const label = typeof getLabel === 'function' ? getLabel() : getLabel;

        if (hide) {
          return null;
        }

        return submenus ? (
          <SubMenu
            key={key}
            title={
              <NavLink to={to + toQuery(query)} activeClassName="active-menu">
                <Icon />
                <span>{label}</span>
              </NavLink>
            }
          >
            {renderMenuItems(submenus)}
          </SubMenu>
        ) : (
          <NebulaMenu.Item key={key}>
            <NavLink to={to}>
              <Icon width={20} height={20} />
              <span>{label}</span>
            </NavLink>
          </NebulaMenu.Item>
        );
      },
    );
  }, []);

  const menuContent = useMemo(
    () => renderMenuItems(menuItems),
    [menuItems, renderMenuItems],
  );

  const menuProps = useMemo(
    () => ({
      mode: 'inline',
      inlineCollapsed: false,
      selectedKeys: [getSelectedKey(menuItems || [])],
    }),
    [getSelectedKey, menuItems],
  );

  return (
    <MenuContainer>
      <View className="menu-content">
        <NebulaMenu {...menuProps}>{menuContent}</NebulaMenu>
      </View>
      {isMobile && (
        <View className="menu-footer">
          <NebulaMenu mode="inline" defaultSelectedKeys={['profile']}>
            <NebulaMenu.ItemGroup key="g2" title="BAO Systems">
              <NebulaMenu.Item key="profile">
                {t('common:menu.profile')}
              </NebulaMenu.Item>
              <NebulaMenu.Item key="help">
                {t('common:menu.help')}
              </NebulaMenu.Item>
              <NebulaMenu.Item key="signOut">
                {t('common:menu.signOut')}
              </NebulaMenu.Item>
            </NebulaMenu.ItemGroup>
          </NebulaMenu>
        </View>
      )}
    </MenuContainer>
  );
};

export default Menu;
