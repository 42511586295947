import { UserAccountSectorEnum } from '@/shared/constants/user';
import { UpdateUserProfile } from '@/shared/interfaces/services';
import { Translation } from './api';

export interface UserProfile extends UpdateUserProfile {
  id: string;
}

export interface ActiveUser {
  username?: string;
  name?: string;
  email?: string;
  auths?: UserRolesTypes;
  userGroups?: UserGroupType[];
  sector?: string;
  id?: string;
  customProperties?: {
    'custom:company': string;
    'custom:sector': string | UserAccountSectorEnum;
    [key: string]: string;
  };
  profile?: UserProfile;
}

export enum ClientDomainEnum {
  REGULAR = 'REGULAR',
  PEPFAR_DATA_MART = 'PEPFAR_DATA_MART',
}

export enum PreferredLoginEnum {
  BAO_MANAGER = 'BAO_MANAGER',
  ANALYTICS_PLATFORM = 'ANALYTICS_PLATFORM',
}

export enum UserRolesEnum {
  ROLE_MANAGE_VIEW = 'ROLE_MANAGE_VIEW',
  ROLE_SEE_SCHEMA = 'ROLE_SEE_SCHEMA',
  ROLE_SEE_ROUTE = 'ROLE_SEE_ROUTE',
  ROLE_SEE_DESTINATION = 'ROLE_SEE_DESTINATION',
  ROLE_MANAGE_ROUTE = 'ROLE_MANAGE_ROUTE',
  ROLE_MANAGE_USER = 'ROLE_MANAGE_USER',
  ROLE_SEE_INSTANCE = 'ROLE_SEE_INSTANCE',
  ROLE_SEE_VIEW = 'ROLE_SEE_VIEW',
  ROLE_MANAGE_PUBLIC_DATASET_CATALOG = 'ROLE_MANAGE_PUBLIC_DATASET_CATALOG',
  ROLE_VIEW_DATA_ANALYTICS_PLATFORM = 'ROLE_VIEW_DATA_ANALYTICS_PLATFORM',
  ROLE_SEE_DATA_PIPELINE = 'ROLE_SEE_DATA_PIPELINE',
  ROLE_MANAGE_INSTANCE = 'ROLE_MANAGE_INSTANCE',
  ROLE_VIEW_DATA_ALL_DATA_PIPELINE = 'ROLE_VIEW_DATA_ALL_DATA_PIPELINE',
  ROLE_GET_FILE = 'ROLE_GET_FILE',
  ROLE_MANAGE_DATA_PIPELINE = 'ROLE_MANAGE_DATA_PIPELINE',
  ROLE_MANAGE_SCHEMA = 'ROLE_MANAGE_SCHEMA',
  ROLE_SEE_VARIABLE = 'ROLE_SEE_VARIABLE',
  ROLE_MANAGE_VARIABLE = 'ROLE_MANAGE_VARIABLE',
  ROLE_MANAGE_DESTINATION = 'ROLE_MANAGE_DESTINATION',
  ROLE_MANAGE_SETTING = 'ROLE_MANAGE_SETTING',
  ROLE_SEE_DATA_QUALITY_CHECK = 'ROLE_SEE_DATA_QUALITY_CHECK',
  ROLE_MANAGE_DATA_QUALITY_CHECK = 'ROLE_MANAGE_DATA_QUALITY_CHECK',
  ROLE_SEE_DATA_QUALITY_CHECK_GROUP = 'ROLE_SEE_DATA_QUALITY_CHECK_GROUP',
  ROLE_MANAGE_DATA_QUALITY_CHECK_GROUP = 'ROLE_MANAGE_DATA_QUALITY_CHECK_GROUP',
  ROLE_MANAGE_FIREWALL_RULE = 'ROLE_MANAGE_FIREWALL_RULE',
  ROLE_SEE_WORKFLOW = 'ROLE_SEE_WORKFLOW',
  ROLE_MANAGE_WORKFLOW = 'ROLE_MANAGE_WORKFLOW',
}

export type UserRolesTypes =
  | keyof typeof UserRolesEnum
  | Array<keyof typeof UserRolesEnum>
  | UserRolesEnum
  | Array<UserRolesEnum>;

export interface UserGroupType {
  code: string;
  created: string;
  id: string;
  lastUpdated: string;
  name: string;
  translations?: Translation[];
}

export enum AuthenticationChallengesEnum {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  CONFIRM_SIGNUP = 'CONFIRM_SIGNUP',
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  MFA_SETUP = 'MFA_SETUP',
}

export enum MFATypeEnum {
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

export interface UserRoles {
  ROLE_MANAGE_INSTANCE: UserRolesEnum;
  ROLE_MANAGE_ROUTE: UserRolesEnum;
  ROLE_SEE_INSTANCE: UserRolesEnum;
  ROLE_MANAGE_DATA_PIPELINE: UserRolesEnum;
}

export interface ActiveClient {
  domain?: string;
  id?: string;
  name: string;
  code?: string;
  pipelinesEnabled: boolean;
}

export interface UserInfo {
  activeUser: ActiveUser;
  activeClient: ActiveClient;
  forwardTo: keyof typeof PreferredLoginEnum;
}
