import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body,
    html {
      margin: 0;
      padding: 0;
      color: #404040;
      font-weight: 400;
      background-color: #fefefe;
      font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      overflow-x: hidden;
    }
    
    body * {
      box-sizing: border-box;
      font-family: Roboto, sans-serif;
      letter-spacing: normal;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
    }
      
    body *:not(.InovuaReactDataGrid):not(.InovuaReactDataGrid *):not(div[role="tree"].ant-tree *)
    {
      scroll-behavior: smooth;
    }    
    
    body::-webkit-scrollbar-track {
      background-color: #f8f9fa;
    }
    
    :root {
      font-size: 62.5% !important;
      --toastify-toast-min-height: 44px;

      --toastify-color-info: ${({ theme: { tokens } }) =>
        tokens.COLOR_BRAND_050};
      --toastify-text-color-info: ${({ theme: { tokens } }) =>
        tokens.COLOR_BRAND_500};
      
      --toastify-color-success: ${({ theme: { tokens } }) =>
        tokens.COLOR_SEMANTIC_SUCCESS_LIGHT};
      --toastify-text-color-success: ${({ theme: { tokens } }) =>
        tokens.COLOR_SEMANTIC_SUCCESS_DARK};
      
      --toastify-color-warning: ${({ theme: { tokens } }) =>
        tokens.COLOR_SEMANTIC_WARNING_LIGHT};
      --toastify-text-color-warning: ${({ theme: { tokens } }) =>
        tokens.COLOR_SEMANTIC_WARNING_DARK};

      --toastify-color-error: ${({ theme: { tokens } }) =>
        tokens.COLOR_SEMANTIC_ERROR_LIGHT};
      --toastify-text-color-error: ${({ theme: { tokens } }) =>
        tokens.COLOR_SEMANTIC_ERROR_DARK};
    }
    
    #root-portal {
      display: flex;
      align-content: center;
      justify-content: center;
    }
    
    a {
      text-decoration: none;
      color: ${({ theme: { tokens } }) => tokens.COLOR_ACCENT};
    }
    
    a:hover,
    a:focus {
      text-decoration: none;
      color: ${({ theme: { tokens } }) => tokens.COLOR_BRAND_500};
    }
    
    button {
      outline: none;
      cursor: pointer;
    }
    
    ::selection {
      color: #fff;
      background: ${({ theme: { tokens } }) => tokens.COLOR_NEUTRAL_GRAY_800};
      text-shadow: none;
    }
    
    ::-moz-selection {
      color: #fff;
      background: ${({ theme: { tokens } }) => tokens.COLOR_NEUTRAL_GRAY_800};
      text-shadow: none;
    }
    
    #root {
      flex: 1;
      height: 100%;
    }
    
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal button,
    .ant-modal button:active,
    .ant-modal button + button,
    .ant-modal button + button:active,
    .ant-modal button:focus,
    .ant-modal button + button:focus {
      border: 0;
      box-shadow: none;
      background-color: transparent;
      color: ${({ theme: { tokens } }) => tokens.COLOR_BRAND_500};
      font-size: 1.6rem;
      font-weight: 500;
      text-shadow: none;
    }
    
    
    .ant-modal button:hover,
    .ant-modal button + button:hover {
      color: #13256F;
      background-color: transparent;
      box-shadow: none;
      text-shadow: none;
    }
    
    /* TABLE */
    
    
    .ant-table-pagination {
      display: flex;
      width: 100%;
    }
    .ant-pagination-total-text {
      flex: 1;
    }
    
    .ant-table table tr.ant-table-placeholder:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td:only-child,
    .ant-table table tr.ant-table-placeholder:hover {
      background-color: transparent;
    }
    
    .ant-table .ant-table-thead th.ant-table-cell {
      white-space: nowrap;
    }
    
    
    .ant-pagination-item,
    .ant-pagination .ant-pagination-options .ant-select-selector,
    .ant-pagination .ant-pagination-item-link {
      border-radius: 4px;
    }
    
    .ant-modal-confirm-content {
      white-space: pre-line;
    }
    
    ul.CodeMirror-hints {
      z-index: 9999;
    }
    
    /* ANTD NOTIFICATION */
    .ant-message-info .anticon {
      color: #4667ED;
    }
`;

export default GlobalStyles;
