import * as React from 'react';
import { Drawer, Modal, View, Text, TOKENS } from '@baosystems/nebula';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Arrow, Close as CloseIcon } from '@/shared/assets/icons/index';
import styles from '../styles';
import { useDrawer } from './LayoutDrawerContext';
import { ConfirmModal } from '@/shared/interfaces/form';
import { MAX_WIDTH_DRAWER_CONTENT } from '@/shared/constants/ui';

const DrawerContainer = styled(View)(styles.drawer);
const Header = styled(View)(styles.drawerHeader);
const CloseButton = styled(View)(styles.drawerClose);
const Title = styled(Text)(styles.drawerTitle);
const TitleBack = styled(Text)(styles.drawerTitleBack);
const Content = styled(View)(styles.drawerContent);

export const DrawerHeader = ({
  title,
  to,
  handleClose,
  confirmClose,
  confirmToCloseContent,
  useCloseArrow,
  children,
}: {
  title?: any;
  to?: any;
  handleClose?: Function;
  confirmClose?: boolean;
  confirmToCloseContent?: ConfirmModal;
  useCloseArrow?: boolean;
  children?: any;
}) => {
  const { confirm } = Modal;
  const { drawer: { confirmToClose = null } = {}, close } = useDrawer() || {};

  const onClose = (callbackURL?: string) => {
    const closeFn = typeof handleClose === 'function' ? handleClose : close;
    const finalConfirmClose = confirmClose ?? confirmToClose;
    if (finalConfirmClose) {
      confirm({
        ...confirmToClose,
        ...confirmToCloseContent,
        centered: true,
        onOk: () => closeFn(callbackURL),
      });
    } else {
      closeFn(callbackURL);
    }
  };

  const handlerOnCloseClick = to && to.path ? () => onClose(to.path) : onClose;

  return (
    <>
      <Header>
        <CloseButton onClick={handlerOnCloseClick}>
          {(to && to.title && to.path) || useCloseArrow ? (
            <Arrow />
          ) : (
            <CloseIcon />
          )}
        </CloseButton>
        <Title>
          {to && to.title && (
            <TitleBack onClick={handlerOnCloseClick}>{to.title} |</TitleBack>
          )}
          {title}
        </Title>
        {children}
      </Header>
    </>
  );
};

const LayoutDrawer = ({
  title,
  children,
  callbackURL,
  maxContentWidth,
  width,
  isMobile,
  closeConfirm,
  ...otherProps
}: any): React.FunctionComponentElement<any> => {
  const history = useHistory();
  const { confirm } = Modal;
  const {
    drawer: { visible, confirmToClose },
    close,
  } = useDrawer();

  const onClose = () => {
    if (confirmToClose) {
      const confirmConfig: ConfirmModal = {
        icon: null,
        ...confirmToClose,
        onOk: () => close(),
      };
      confirm(confirmConfig);
    } else {
      close();
    }
  };

  const handleAfterVisibleChange = (isVisible: boolean) => {
    if (!isVisible) {
      if (callbackURL) {
        history.push(callbackURL);
      } else {
        history.goBack();
      }
    }
  };

  return (
    <DrawerContainer className="drawer-container">
      <Drawer
        levelMove={false}
        visible={visible}
        {...otherProps}
        width={width}
        closable={false}
        onClose={onClose}
        afterVisibleChange={handleAfterVisibleChange}
        getContainer={document.getElementById('root-portal')}
        style={{ transform: '' }}
        drawerStyle={{
          backgroundColor: TOKENS.baosystems.COLOR_NEUTRAL_GRAY_050,
          display: 'flex',
        }}
        bodyStyle={{ padding: 0, width: '100%' }}
        keyboard={false}
      >
        <Content maxContentWidth={maxContentWidth} isMobile={isMobile}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { isDrawerContent: true }),
          )}
        </Content>
      </Drawer>
    </DrawerContainer>
  );
};

LayoutDrawer.defaultProps = {
  width: '50%',
  maxContentWidth: MAX_WIDTH_DRAWER_CONTENT + 'px',
};

export default LayoutDrawer;
