import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import createRootReducer from './root-reducers';
import apiMiddleware from '../utils/middleware/api';

export const history = createHashHistory({ hashType: 'slash' });

const middleWares = [thunk, routerMiddleware(history), apiMiddleware];

process.env.REACT_APP_DEV_REDUX_ROUTER_LOGS === 'true' &&
  middleWares.push(logger);

export default function configureStore(preloadedState) {
  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleWares)),
  );
}
