import { css } from 'styled-components';
import { MAX_WIDTH_CONTENT, MIN_CONTENT_PADDING } from '@/shared/constants/ui';

const styles = {
  container: ({ theme }) => css`
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 100vh;
    min-width: 320px;
    flex-direction: column;
    background-color: ${theme.tokens.COLOR_NEUTRAL_GRAY_050};

    .ant-drawer {
      overflow-x: hidden;
    }

    .ant-drawer-content-wrapper {
      transition:
        width 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
        transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
        box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
        -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
        -webkit-box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    }
  `,
  sidebar: ({ isMobile }) => css`
    display: ${isMobile ? 'none' : 'flex'};
    width: 240px;
    background-color: ${({ theme }) => theme.tokens.COLOR_NEUTRAL_GRAY_050};
    border-right: 1px solid
      ${({ theme }) => theme.tokens.COLOR_NEUTRAL_GRAY_200};
    flex-wrap: wrap;
    overflow-x: hidden;
    div {
      overflow-x: hidden;
    }
  `,
  sidebarFooter: () => css`
    align-self: flex-end;
    width: 100%;
    margin: 25px;
    color: ${({ theme }) => theme.tokens.COLOR_NEUTRAL_GRAY_600};
  `,
  main: () => css`
    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
    align-items: stretch;
    overflow-x: hidden;
  `,
  contentContainer: ({ theme: { tokens } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    margin: 0 auto;
    flex: 1;
    overflow: auto;
    min-height: 600px;
    max-height: calc(100vh - 63px);
    position: relative;
    overflow: hidden;
    && {
      .ant-tabs-tab {
        letter-spacing: 0.5px;
      }
      .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
        padding: 0;
        width: 100%;
      }
      .tabs-content {
        position: relative;
        min-height: 100px;
        margin-bottom: 20px;
        &.wide-wrapper {
          max-width: ${MAX_WIDTH_CONTENT + 60}px;
        }
      }
      .wide-wrapper,
      .ant-tabs-nav-scroll {
        width: 100%;
        justify-self: center;
        margin: 0 auto;
        padding: 0 ${tokens.SPACING_BIG_MIN}px;
      }
      && {
        > .wide-wrapper {
          width: 100%;

          max-width: 100%;

          > div {
            max-width: ${MAX_WIDTH_CONTENT}px;
            margin: 0 auto;
          }
        }
      }

      .ant-tabs-tabpane {
        display: flex;
        justify-content: center;
      }
    }

    .toolbar {
      > div {
        align-items: center;
        min-height: 69px !important;
        padding: ${tokens.SPACING_SMALL_MAX}px 0;
      }
      .toolbar-section {
        background-color: ${tokens.COLOR_NEUTRAL_WHITE};
      }
      .toolbar-section:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 ${tokens.COLOR_NEUTRAL_GRAY_100};
      }
    }
  `,
  loaderContainer: ({ theme: { tokens }, lightBG }) => css`
    padding-top: ${MIN_CONTENT_PADDING}px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${() =>
      lightBG ? tokens.COLOR_NEUTRAL_GRAY_100 : tokens.COLOR_BRAND_500};
    z-index: 100;
  `,
  notificationContainer: () => css`
    display: flex;
    justify-content: center;
    height: 0;
  `,
  toastContainer: ({ theme: { tokens } }) => css`
    width: 50%;
    & .Toastify__toast-theme--colored.Toastify__toast--info {
      border: 1px solid #a1c2fb;
    }
    & .Toastify__toast-theme--colored.Toastify__toast--success {
      border: 1px solid #aed9a5;
    }
    & .Toastify__toast-theme--colored.Toastify__toast--warning {
      border: 1px solid #fad6a3;
    }
    & .Toastify__toast-theme--colored.Toastify__toast--error {
      border: 1px solid #e5a4a0;
    }
    & .Toastify__toast-body {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    & .close-toast {
      display: flex;
      align-items: center;
      margin-right: 6px;
    }
    & .close-toast > svg {
      height: 13px;
      width: 13px;
    }
  `,
  arrowBack: () => css`
    transform: scale(-1);
    #arrow,
    #Clean {
      transition: all 200ms ease-in-out;
    }
  `,
  backButtonContainer: ({ theme }) => css`
    width: 64px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    &:hover {
      #arrow {
        fill: ${theme.tokens.COLOR_BRAND_500};
      }
      #Clean {
        transform: translate3d(-2px, 0, 0);
      }
    }
  `,
  drawer: ({ theme }) => css`
    background-color: ${theme.tokens.COLOR_NEUTRAL_GRAY_050};
  `,
  drawerHeader: ({ theme: { tokens } }) => css`
    display: flex;
    width: 100%;
    min-width: 100%;
    height: 64px;
    background-color: ${tokens.COLOR_NEUTRAL_WHITE};
    align-items: center;
    border-bottom: 1px solid ${tokens.COLOR_NEUTRAL_GRAY_100};
    margin-bottom: 16px;
  `,
  drawerTitle: ({ theme: { tokens } }) => css`
    && {
      color: ${tokens.COLOR_NEUTRAL_GRAY_700};
      font-size: 2.4rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  drawerTitleBack: ({ theme: { tokens } }) => css`
    && {
      cursor: pointer;
      color: ${tokens.COLOR_NEUTRAL_GRAY_500};
      font-size: 2.4rem;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 5px;

      &:hover {
        color: ${tokens.COLOR_BRAND_500};
      }
    }
  `,
  drawerContent: ({ theme: { tokens }, maxContentWidth, isMobile }) =>
    isMobile
      ? css`
          && {
            .drawer-content {
              margin: 0 auto;
              height: calc(100vh - 65px);
              overflow-y: auto;
              scroll-behavior: smooth;
              padding: 10px 20px;

              &.tabs-content {
                min-height: 200px;
              }

              > div {
                max-width: ${maxContentWidth || '100%'};
              }
            }
          }
        `
      : css`
          && {
            .drawer-content {
              max-width: ${parseInt(maxContentWidth.replace('px', '')) +
                tokens.SPACING_BIG_MAX * 2 +
                'px' || '100%'};
              margin: 20px auto 0;
              padding: 0 ${tokens.SPACING_BIG_MAX}px;
              &.tabs-content {
                min-height: 200px;
              }

              > div {
                max-width: ${maxContentWidth || '100%'};
              }
            }

            .toolbar {
              > div {
                align-items: center;
                min-height: 69px !important;
                padding: ${tokens.SPACING_SMALL_MAX}px 0;
              }
              .toolbar-section {
                background-color: ${tokens.COLOR_NEUTRAL_WHITE};
              }
              .toolbar-section:not(:last-child) {
                box-shadow: inset 0 -1px 0 0 ${tokens.COLOR_NEUTRAL_GRAY_100};
              }
            }
          }
        `,
  drawerClose: ({ theme: { tokens } }) => css`
    width: 63px;
    height: 63px;
    background-color: ${tokens.COLOR_NEUTRAL_WHITE};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
    transition: background-color 300ms ease-in-out;

    svg g {
      fill: ${tokens.COLOR_NEUTRAL_GRAY_700};
      transition: fill 200ms ease-out;
    }

    &:hover {
      background-color: ${tokens.COLOR_BRAND_050};
      svg g {
        fill: ${tokens.COLOR_BRAND_500};
      }
    }

    &:active {
      background-color: ${tokens.COLOR_BRAND_100};
    }
  `,
};

export default styles;
