import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import configureStore from '@/shared/state/configure-store';
import './i18n';
import * as serviceWorker from './serviceWorker';
import routes from './routes';
import { useTranslation } from 'react-i18next';
import {
  ReactQueryConfigProviderProps,
  ReactQueryConfigProvider,
} from 'react-query';
import { importStyles } from '@/shared/services/domainProvider.service';
import { ErrorBoundary } from 'react-error-boundary';
import { Result } from 'antd';
import '@/shared/assets/fonts/fonts.css';
importStyles();

let RQDevtools = null;

const config = configureStore();
const queryConfig: ReactQueryConfigProviderProps = {
  config: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
};

if (process.env.REACT_APP_USE_API_MOCK === 'true') {
  require('@/shared/mocks/api-mock');
}

if (module.hot) {
  module.hot.accept();
  let { ReactQueryDevtools } = require('react-query-devtools');
  RQDevtools = ReactQueryDevtools;
}

function ErrorFallback(error: any, resetErrorBoundary: any) {
  const { t } = useTranslation();
  return (
    <>
      <Result
        status="warning"
        title={error.message}
        subTitle={t('common:messages.error.unknown.message')}
        extra={t('common:messages.error.unknown.action')}
      />
    </>
  );
}

render(
  <>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ReactQueryConfigProvider config={queryConfig.config}>
        <Suspense fallback={null}>
          <Provider store={config.store()}>
            <ConnectedRouter history={config.history}>
              {renderRoutes(routes)}
            </ConnectedRouter>
          </Provider>
        </Suspense>
      </ReactQueryConfigProvider>
      {RQDevtools && <RQDevtools styles={{ zIndex: 9999 }} />}
    </ErrorBoundary>
  </>,
  document.getElementById('root'),
);

serviceWorker.register();
